@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.modal{
  [data-pc-section="headertitle"]{
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
  }
}
.wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title{
  margin-bottom: 24px;
  span{
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #48494F;
  }
}
.form{
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  input{
    width: 100%;
  }
}
.footer{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  
  .label{
    color: #828282;
  }
  button{
    max-width: 316px;
    width: 100%;
  }
}