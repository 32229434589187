@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.modal {
    &[data-pc-name="dialog"] {
        margin: 0;
    }
    [data-pc-section="content"] {
        padding: 16px;
        background: #F4F3EC;
    }
    [data-pc-section="header"] {
        padding: 40px 40px 0 40px;
        background: #F4F3EC;
    }
}
.isCenter{
    [data-pc-section="content"] {
        padding: 24px 40px 40px 40px;
    }
}
.header-btn {
    &:focus,
    &:active,
    &:hover {
        background: none;
        border: none;
        box-shadow: none;
    }
}
.isMobile {
    [data-pc-section="header"] {
        border-bottom: none;
    }
    [data-pc-section="content"] {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    margin: 0;
}
