@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.navbar{
    width: 100%;
}
.items{
    display: flex;
    gap: 30px;
    width: 100%;
    justify-content: space-between;
    .item{
        display: flex;
        flex-direction: column;
        position: relative;

        &:hover{
            .subitems-wrapper{
                opacity: 1;
                display: block;
            }
        }
    }

    .link{
        text-decoration: none;
        color: inherit;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-transform: uppercase;
        cursor:pointer;
        .count{
        color: #7E7C7A;
        }

        &.submenu{
            display: flex;
            gap: 4px;
            align-items: center;
        }
    }
    @include media-max(800px){
        &{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        gap: 15px;
            justify-items: center;
    }
    }

}
.subitems-wrapper{
    position: absolute;
    opacity: 0;
    display: none;
    transition: all 0.5s ease;
    width:300px;
    z-index: 1;

    .subitems-container{
        position: relative;
        top: 40px;
        left: 0;
        background: #EFEEE6;
        border: 1px solid #EAE7DC;

        .subitems{
            padding: 30px;
            background:linear-gradient(to right, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 0 / 100% 1px no-repeat,
            linear-gradient(to right, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 100% / 100% 1px no-repeat,
            linear-gradient(to bottom, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 0 / 1px 100% no-repeat,
            linear-gradient(to bottom, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 100% 0 / 1px 100% no-repeat;
            display: flex;
            flex-direction: column;
            gap: 20px;

        }

        @include media-max($screen-tablet){
            left: -110px;
        }
    }

}

