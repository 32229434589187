@mixin hidden-scrollbar() {
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin svg-color($color, $transition-duration: 0ms) {
    transition: all $transition-duration;
    fill: $color;
    path {
        transition: all $transition-duration;
        fill: inherit;
    }
}

@mixin flex-center() {
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin font-SegoeUI {
    font-family: "ForumRegular", sans-serif;
}

@mixin text-Display1 {
    --fs: 96;
    --lh: 112;
    @include font-SegoeUI();
    font-weight: 600;
    font-size: 96px;
    line-height: 112px;
}
@mixin text-Display2 {
    --fs: 60;
    --lh: 72;
    @include font-SegoeUI();
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
}

@mixin text-Headline1 {
    --fs: 48;
    --lh: 56;
    @include font-SegoeUI();
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
}
@mixin text-Headline2 {
    --fs: 40;
    --lh: 48;
    @include font-SegoeUI();
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
}
@mixin text-Headline3 {
    --fs: 34;
    --lh: 40;
    @include font-SegoeUI();
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
}
@mixin text-Headline4 {
    --fs: 32;
    --lh: 40;
    @include font-SegoeUI();
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
}
@mixin text-Headline5 {
    --fs: 28;
    --lh: 36;
    @include font-SegoeUI();
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
}
@mixin text-Headline6 {
    --fs: 24;
    --lh: 32;
    @include font-SegoeUI();
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.15px;
}

@mixin text-Subtitle1 {
    --fs: 22;
    --lh: 28;
    @include font-SegoeUI();
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
}
@mixin text-Subtitle2 {
    --fs: 16;
    --lh: 24;
    @include font-SegoeUI();
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
}
@mixin text-Subtitle3 {
    --fs: 14;
    --lh: 20;
    @include font-SegoeUI();
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
}

@mixin text-Body1 {
    --fs: 16;
    --lh: 20;
    @include font-SegoeUI();
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.2px;
}
@mixin text-Body2 {
    --fs: 14;
    --lh: 20;
    @include font-SegoeUI();
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
@mixin text-Body3 {
    --fs: 12;
    --lh: 16;
    @include font-SegoeUI();
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.2px;
}

@mixin text-Button {
    --fs: 16;
    --lh: 24;
    @include font-SegoeUI();
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
}
@mixin text-ButtonLink {
    --fs: 18;
    --lh: 28;
    @include font-SegoeUI();
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
    letter-spacing: 0.1px;
    text-decoration: none;
}
@mixin text-ButtonSmall {
    --fs: 14;
    --lh: 20;
    @include font-SegoeUI();
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
}

@mixin text-Caption {
    --fs: 12;
    --lh: 16;
    @include font-SegoeUI();
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
}

@mixin text-Overline {
    --fs: 10;
    --lh: 16;
    @include font-SegoeUI();
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.5px;
}
@mixin style-ButtonNeon {
    @include text-Button();
    border-radius: 9px;
    background: $color-purple;
    box-shadow: 0 10px 25px 0 rgba(123, 104, 238, 0.4);
    border: 1px solid $color-purple;
    color: #fff;
}

@mixin touch-hover($selector) {
    @at-root {
        @media (hover: hover) {
            #{$selector}:hover {
                @content;
            }
        }
        @media (hover: none) {
            #{$selector}:active {
                @content;
            }
        }
    }
}

@mixin hidden-scrollbar() {
    &::-webkit-scrollbar {
        display: none;
    }
}
