@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.wrapper{
  display: flex;
  flex-direction: column;
  gap: 60px;
  h1{
    font-weight: 400;
    font-size: 128px;
    line-height: 90%;
    color: #232120;
  }
  .content{
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-bottom: 180px;
  }
  @include media-max(1100px){
    h1{
      font-size: 80px;
    }

  }
  @include media-max(720px){
    h1{
      font-size: 60px;
    }
    .title{
      font-size: 14px;
    }
  }
  @include media-max(520px){
    h1{
      font-size: 48px;
    }
  }
}