@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.text-field{
  padding: 18px 26px;
  width: 250px;
  height: 58px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);

  &.error{
    border: 1px solid #f06969;
  }

  &::placeholder{
    color: #BDBDBD;
  }
    &.dark{
      background:#404146;
      color: #fff;
    }
    &.light{
      background:#ffffff;
      color: #000;
      border: 1px solid #BDBDBD;
    }
}