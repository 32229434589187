@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.form-present{
  border-bottom: 1px solid #EAE7DC;
  border-top: 1px solid #EAE7DC;

  .wrapper{
    display: flex;
    justify-content: space-between;

    .form-content{
      padding: 60px 0;
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 705px;
      width: 100%;

      h2{
        font-weight: 400;
        font-size: 80px;
        line-height: 90%;
      }

      .description{
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #5B5857;

        .link{
          color: #E76325;
        }
      }

      @include media-max(720px){
        h2 {
          font-size: 32px;
          text-align: center;
        }
        .description{
          font-size: 14px;
          text-align: center;
        }
      }
    }

    .form{
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-family: 'ManropeRegular', sans-serif;

      .inputs{
        display: flex;
        gap: 10px;
      }

      .buttons{
        display: flex;
        gap: 10px;
        .privacy{
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          flex: 0 1 50%;
          color: #5B5857;
          i{
            color: #E76325;
          }
          .link{
            color: #232120;
          }
        }
        button{
          flex: 1 1 ;
        }
      }
    }
    @include media-max(1600px){

      flex-direction: column;
      .form-content{
        max-width: 100%;
      }

      .form-image{
        .image{
          width: 100%;
          height: 100%;
        }
      }
    }
    @include media-max(720px){
      .buttons,
      .inputs{
        flex-direction: column;
      }
    }
  }
}