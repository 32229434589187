$color-purple: #7b68ee;
$color-purple-darkness: #6454c7;
$color-purple-light: #c4c4ff;
$color-purple-lighter: #bbc4ef;

$color-light: #ffffff;
$color-lightness: #f7f7ff;
$color-lighter: #efefff;

$color-neutral-50: #787579;

$color-red-60: #e41110;
$color-red-light: #b73d3d;

$color-dark: #1c1b1f;
$color-dark-light: #79747e;
$color-dark-lighter: #c9c5ca;
$gradient-primary: linear-gradient(83.96deg, #45c4f9 0%, #7d09ff 50.33%, #ff0be5 100%);

$transition-default: 0.5s all;
$transition-fast: 0.3s all;

// old
$color-purple-blue: #6454c7;
$color-silver-crayola: rgba(205, 197, 194);
