@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.hidden{
  opacity: 0;
  pointer-events: none;
}
.logo{
  cursor: pointer;
  .image{
    width: 120px;
    height: 65px;
  }
}