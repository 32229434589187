@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.header{
  width: 100%;
  .wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 15px 24px;

    @include media-max(1100px){
      justify-content: center;
    }
  }

}
.left{
  display: flex;
  align-items: center;
  gap: 40px;

  @include media-max(1024px) {
    flex-direction: row-reverse;
  }
}
.nav{
  display: flex;
  align-items: center;
  gap: 60px;

  .sidebar-button{
    color: #7E7C7A;
    display: none;
    border: none;
    background: none;
    &:hover{
      background: none;
      border: none;
    }
  }


  .link{
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: #ffffff;
    text-decoration: none;
  }
  @include media-max(1024px){
    border: 1px solid #EAE7DC;

    .sidebar-button{
      display: block;
      transform: rotate(90deg);
    }
    .navbar-header{
      display: none;
    }
  }
}
.phone-link{
  border: 1px solid #EAE7DC;
  color: #7E7C7A;
  display: none;
  background: none;
  border-radius: 0;
  &:hover{
    background: none;
    border: none;
  }
  @include media-max(1100px){
    display: block;
  }
}
.right{
  display: flex;
  gap: 30px;
  align-items: center;
  .socials{
    display: flex;
    align-items: center;
    gap: 10px;
    .social{
      cursor: pointer;
    }
  }
  .contacts{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Mulish, sans-serif;
    a{
      text-decoration: none;
      font-size: 18px;
      line-height: 140%;
    }

    .phone{
      font-weight: 400;
      font-size: 24px;
      line-height: 90%;
      color: #232120;
    }
    .email{
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #E76325;
    }
    @include media-max($screen-notebook){
      &{
        flex-direction: column;
        align-items: flex-start;
        margin-top: 50px;
        a{
          color: #000000;
          font-size: 25px;
          line-height: 160%;
        }
      }
    }
  }
  @include media-max(1300px){
    display: none;
  }
}

@include media-max($screen-notebook){
  .mobile{
    display: none;
  }
}
.panel-menu{
  [data-pc-section="menucontent"],
  [data-pc-section="headercontent"] {
    background: none;
    border:none;
  }
}
.sidebar{
  &[data-pc-section="root"]{
    width: 100%;
  }
  [data-pc-section="header"]{
    display: none;
  }
  [data-pc-section="content"]{
    background: #F4F3EC;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
.nav{
  border: none;
  height: 100%;
  .navbar-container{
    height: 100%;
    background:linear-gradient(to right, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 0 / 100% 1px no-repeat,
    linear-gradient(to right, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 100% / 100% 1px no-repeat,
    linear-gradient(to bottom, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 0 / 1px 100% no-repeat,
    linear-gradient(to bottom, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 100% 0 / 1px 100% no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }
  .navbar-items{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }
}
  .sidebar-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
  }
}
.sidebar-right{
  display: flex;
  gap: 30px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  .socials{
    display: flex;
    align-items: center;
    gap: 10px;
    .social{
      font-size: 30px;
      cursor: pointer;
    }
  }
  .contacts{
    display: flex;
    flex-direction: column;
    align-items: center;
    a{
      text-decoration: none;
      font-size: 18px;
      line-height: 140%;
    }
    .email{
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #E76325;
    }
  }
}