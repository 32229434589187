@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.card-equipment{
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 1 1;
  align-items: center;
  cursor: pointer;
  max-height: 507px;
  overflow: hidden;
  transition: max-height .3s ease-in-out;

  .image-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EAE7DC;
    width: 100%;

    .image{
      width: 100%;
    }
  }
  .content{
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title{
      font-weight: 400;
      font-size: 22px;
      line-height: 140%;
    }
    .description{
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #5B5857;
    }
  }

  @include media-min(721px) {
    &:hover {
      max-height: 592px;
      transition: max-height .3s ease-in-out;
    }
  }

  @include media-max(720px){
    max-height: 592px;
    .content{
      .title{
        font-size: 17px;
      }
      .description{
        font-size: 14px;
      }
    }
    .image-wrapper{
      .image{
        height: 100%;
      }
    }
  }
}